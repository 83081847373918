// *PACKAGES* //
import React, { useState, useEffect, useMemo } from "react";
// *FUNCTIONS* //
import CheckBotStatus from "./components/CheckBotStatus";
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from 'react-router-dom';
import DataEntry from "./components/DataEntry";
import Filter from "./components/Filter";
import TradingViewWidget from "./components/TradingViewWidget";
import Header from "./components/Header";
// *ASSETS* //
// *STYLES* //
import "./style/In.css";
import "./style/mobile.css";
import "./style/loader.css";

import Onboarding from "./components/Onboarding";

// *APP FUNCTION* //
export default function App() {
  // TODO:
  const [items, setItems] = useState([]);
  // TODO:
  const [visibleItemsCount, setVisibleItemsCount] = useState(5);
  // TODO:
  const [searchQuery, setSearchQuery] = useState("");
  // TODO:
  const [filterStatus, setFilterStatus] = useState("all");
  // TODO:
  const [filterProfitLoss, setFilterProfitLoss] = useState("all");
  // TODO:
  const [showPopup, setShowPopup] = useState(true);
  // TODO:
  const [fadeOutPopup, setFadeOutPopup] = useState(false);
  // TODO:
  const [fadeOut, setFadeOut] = useState(false);
  // TODO:
  const [loadingMessage, setLoadingMessage] = useState("Loading your data");
  // TODO:
  const [isNewSignal, setIsNewSignal] = useState(false);
  // TODO:
  const [winsCount, setWinsCount] = useState(0);
  // TODO:
  const [lossesCount, setLossesCount] = useState(0);
  // TODO:
  const [winRate, setWinRate] = useState(0);
  // TODO:
  const [, setIsLoading] = useState(true);
  // TODO:
  const [scrollEnabled, setScrollEnabled] = useState(false);
  // TODO:
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);

  const [filterTradeType, setFilterTradeType] = useState("all"); // Novy stav pro typ obchodu

  const [showOnboarding, setShowOnboarding] = useState(true);

  const [timeRange, setTimeRange] = useState('all'); // Výchozí hodnota může být '24h'

  const coins = ['MANAUSDT.P', 'STXUSDT.P', 'ARPAUSDT.P', 'ARBUSDT.P', 'OPUSDT.P', 'SOLUSDT.P', 'XRPUSDT.P', 'BLURUSDT.P', 'SUPERUSDT.P', '1000BONKUSDT.P']; 



  

  // Set scrollIntoView behavior on smooth
  useEffect(() => {
    const element = document.getElementById("application");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  // TODO:
  let wakeLock = null;
  // WebSocket init var
  let websocket = null;
  // Heartbeat interval var
  let heartbeat_interval = null;

  // TODO: ** //
  // const registerServiceWorker = async () => {
   //  if ("serviceWorker" in navigator) {
   //    try {
    //     const registration = await navigator.serviceWorker.register("/service-worker.js");
    //     console.log("Service worker registered");
 //
   //      if ('sync' in registration) {
   //        navigator.serviceWorker.ready.then((reg) => {
    //         return reg.sync.register('sync-new-signals');
    //       }).then(() => {
    //         console.log('Background Sync registered');
    //       }).catch((err) => {
     //        console.error('Background Sync registration failed', err);
    //       });
    //     } else {
     //      console.error('Background Sync not supported');
     //    }
 //
     //  } catch (error) {
    //     console.error("Service worker registration failed", error);
    //   }
   //  }
  // };

  // TODO: ** //
  const requestWakeLock = async () => {
    try {
      wakeLock = await navigator.wakeLock.request('screen');
      wakeLock.addEventListener('release', () => {
        console.log('Screen Wake Lock was released');
      });
      console.log('Screen Wake Lock is active');
    } catch (err) {
      console.error(`${err.name}, ${err.message}`);
    }
  };
  

  // TODO: ** //
  const requestNotificationPermission = () => {
    if (!("Notification" in window)) {
      alert("You have to save ByX App to homescreen to receive push-notifications.");
    } else if (Notification.permission === "granted") {
      setNotificationsEnabled(true);
      new Notification("You already have notifications enabled.");
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          setNotificationsEnabled(true);
          new Notification("Thank you for enabling notifications!");
          subscribeUserToPush();
        }
      });
    }
  };

  function App() {
    const [timeRange, setTimeRange] = useState('all');
    
    // zbytek kódu
  }
  

  // TODO: ** //
  const copyToClipboard = (text) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          console.log("Copied: ", text);
          showAlert("Zkopírováno");
        })
        .catch((err) => console.error("Error with Copy: ", err));
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      textArea.style.position = "fixed";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        const successful = document.execCommand("copy");
        const msg = successful ? "Zkopírováno" : "Chyba";
        console.log(msg);
        showAlert(msg);
      } catch (err) {
        console.error("Error: ", err);
      }
      document.body.removeChild(textArea);
    }
  };

  // TODO: ** //
  const showAlert = (message) => {
    const existingAlertBoxes = document.querySelectorAll(".alertBox");
    const alertBox = document.createElement("div");
    alertBox.classList.add("alertBox");
    alertBox.innerText = message;

    let topPosition = "10px";

    if (existingAlertBoxes.length > 0) {
      const lastAlertBox = existingAlertBoxes[existingAlertBoxes.length - 1];
      const lastAlertBoxBottom = lastAlertBox.offsetTop + lastAlertBox.offsetHeight;
      if (lastAlertBoxBottom + 10 < window.innerHeight) {
        topPosition = `${lastAlertBoxBottom + 10}px`;
      }
    }

    alertBox.style.top = topPosition;
    document.body.appendChild(alertBox);

    setTimeout(() => {
      alertBox.classList.add("hide");
      setTimeout(() => {
        alertBox.parentNode.removeChild(alertBox);
      }, 500);
    }, 2000);
  };

  // TODO: ** //
  const handleShowMore = () => {
    setVisibleItemsCount((prevCount) => prevCount + 10);
  };

  // TODO: ** //
  const closePopup = () => {
    setFadeOutPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 500);
  };

  // TODO: ** //
  const sendNotification = (message) => {
    if (notificationsEnabled) {
      navigator.serviceWorker.getRegistration().then((reg) => {
        reg.showNotification(message);
      });
    }
  };

  // TODO: ** //
  const urlBase64ToUint8Array = (base64String) => {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
    const rawData = window.atob(base64);
    return new Uint8Array(rawData.split("").map((char) => char.charCodeAt(0)));
  };

  // TODO: ** //
  const subscribeUserToPush = async () => {
    const registration = await navigator.serviceWorker.ready;
    const vapidPublicKey = "BMTf4W4tRauY7vWRtIDR4XWHFVkqe1oX7Sf7-I1h-J2JCO45y-APDYHVlxKm4zX_UhMRZrWlf9KjPqbjcMNi2pU";
    const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);

    try {
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: convertedVapidKey,
      });
      console.log("Chyba ověření uživatele:", subscription);
      await sendSubscriptionToServer(subscription);
    } catch (error) {
      console.error("Chyba ověření uživatele:", error);
    }
  };

  // TODO: ** //
  const sendSubscriptionToServer = async (subscription) => {
    try {
      const response = await fetch('/api/subscribe', {
        method: 'POST',
        body: JSON.stringify(subscription),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Bad status code from server.');
      }
      const responseData = await response.json();
      if (!(responseData && responseData.success)) {
        throw new Error('Bad response from server.');
      }
    } catch (error) {
      console.error('Chyba ověření serveru:', error);
    }
  };

  // TODO: ** //
  const filteredItems = items.filter((item) => {
    if (
      item.coin.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (filterStatus === "all" || item.type.toLowerCase().includes(filterStatus)) &&
      (filterProfitLoss === "all" ||
        (filterProfitLoss === "profit" &&
          (item.result === "Profit" ||
            item.result === "Closed Long" ||
            item.result === "Closed Short")) ||
        (filterProfitLoss === "loss" && item.result === "Loss")) &&
      (filterStatus === "opened" || filterTradeType === "all" ||
        (filterTradeType === "short" && item.type.toLowerCase().includes("short")) ||
        (filterTradeType === "long" && item.type.toLowerCase().includes("long")))
    ) {
      return true;
    }
    return false;
  });

  // TODO: ** //
  const coinOptions = useMemo(() => {
    if (!items) return [];
    const allCoins = items.map((item) => item.coin);
    return Array.from(new Set(allCoins));
  }, [items]);

  // TODO: ** //
  document.addEventListener('visibilitychange', () => {
    if (wakeLock !== null && document.visibilityState === 'visible') {
      requestWakeLock();
    }
  });

  // TODO: ** //
  // useEffect(() => {
  //   if (Notification.permission === "granted") {
  //     setNotificationsEnabled(true);
  //   }
   //  registerServiceWorker();
   //  requestWakeLock();
  // }, []);

  // TODO: ** //
  useEffect(() => {
    // Set body::before display to none when the component mounts
    document.body.style.setProperty("--before-display", "none");
    // Set body display to block when the component mounts
    document.body.style.setProperty("--body-display", "block");
  }, []);

  // TODO: ** //
  useEffect(() => {
    const popupTimer = setTimeout(() => {
      setFadeOutPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 500);
      setScrollEnabled(true);
    }, 100);

    return () => clearTimeout(popupTimer);
  }, []);

  // TODO: ** //
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (items.length === 0) {
        setLoadingMessage("Nic nebylo nalezeno, omlouvám se.");
      }
    }, 15000);

    return () => clearTimeout(timeoutId);
  }, [items]);

  // TODO: ** //
  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeOut(true);
      setTimeout(() => setIsLoading(false), 2000);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  // *CONNECT WEBSOCKET FUNCTION* //
  function connect_web_socket() {
    // Create WebSocket
    websocket = new WebSocket(process.env.REACT_APP_WEBSOCKET);

    // Open handle
    websocket.onopen = () => {
      // Console log
     // console.log('WebSocket connection established.');

      // Start heartbeat a ping websocket evry 5 seconds
      heartbeat_interval = setInterval(() => {
        // If ready state is websocket open
        if (websocket.readyState === WebSocket.OPEN) {
          // Send ping
          websocket.send(JSON.stringify({ type: 'ping' }));
        }
      }, 5000);
    };

    // Message handle
    websocket.onmessage = (event) => {
      // Get data from event
      const new_data = JSON.parse(event.data);

      // If type in new_data is pong
      if (new_data.type === 'pong') {
        // Return for ignore
        return;
      }

      // Set itmes for react station
      setItems((prev_items) => {
        // Filter existing ids
        const existing_ids = new Set(prev_items.map((item) => item.id));
        // Get new items where is not existing ids
        const new_items = new_data.filter((item) => !existing_ids.has(item.id));

        // If new items lenght is more then 0
        if (new_items.length > 0) {
          // Set is new signal
          setIsNewSignal(true);
        }

        // Retur array from new and previous items
        return [...new_items, ...prev_items];
      });
    };

    // Close handle
    websocket.onclose = (event) => {
      // Console log
      console.log('WebSocket connection closed.', event);

      // If heartbeat interval is not null
      if (heartbeat_interval) {
        // Clear interval
        clearInterval(heartbeat_interval);
        // Set heartbeat interval var on null
        heartbeat_interval = null;
      }

      // Reconnect after close an 15 seconds
      setTimeout(() => {
        // Console log
        console.log('Reconnecting websocket...');
        // Call connect web socket function
        connect_web_socket();
      }, 15000);
    };

    // Error handle
    websocket.onerror = (error) => {
      // Console error
      console.error('WebSocket error: ', error);
    };
  };

  // *INPUT WEBSOCKET* //
  useEffect(() => {
    // Call connect web socket function for init
    connect_web_socket();

    // Return function
    return () => {
      // If websocket is not null
      if (websocket) {
        // Console log
        console.log('Closing WebSocket...');
        // Close websocket
        websocket.close();
      }

      // If heartbeat interval is not null
      if (heartbeat_interval) {
        // Clear heartbeat interval
        clearInterval(heartbeat_interval);
      }
    };
  }, []);

  // TODO: ** //
  useEffect(() => {
    if (isNewSignal) {
      sendNotification("New Signal");
      setIsNewSignal(false);
    }
  }, [isNewSignal]);

  

  // TODO: ** //
  useEffect(() => {
    const handleScroll = (event) => {
      if (!scrollEnabled) {
        event.preventDefault();
        window.scrollTo(0, 0);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollEnabled]);

  // TODO: ** //
  useEffect(() => {
    let wins = 0;
    let losses = 0;
    items.forEach(item => {
      if (item.result === "Profit" || item.result === "Closed Long" || item.result === "Closed Short") {
        wins++;
      } else if (item.result === "Loss") {
        losses++;
      }
    });
    setWinsCount(wins);
    setLossesCount(losses);
    const currentWinRate = (wins / (wins + losses)) * 100;
    setWinRate(isNaN(currentWinRate) ? 0 : currentWinRate.toFixed(2));

    
  }, [items]);

  // *RETURN JSX OBJECT* //
  return (
<>
  {showOnboarding ? (
    <Onboarding boardingCloseFunc={() => setShowOnboarding(false)} />
  ) : (
    <div>
      <div className={`loader-container ${fadeOut ? "fade-out" : ""}`}>
        <div className="loader"></div>
        <p className="beta-text">Aplikace je v beta verzi, omluvte prosím chyby.</p>
      </div>
      <section>
        <div className="sectionStyle"></div>
        <section className="upper_background">
          <Header />
          <CheckBotStatus />

          {/* Hlavní grid layout */}
          <section className="dash">
            {/* Signály přes tři řádky */}
            <section className="signals">
            <Filter
      coins={coins}
      searchQuery={searchQuery} setSearchQuery={setSearchQuery}
      filterStatus={filterStatus} setFilterStatus={setFilterStatus}
      filterProfitLoss={filterProfitLoss} setFilterProfitLoss={setFilterProfitLoss}
      filterTradeType={filterTradeType} setFilterTradeType={setFilterTradeType}
    />
  <div className="signals-container">
    {filteredItems.length > 0 ? (
      filteredItems
        .slice(0, visibleItemsCount)
        .map((item, index) => (
          <DataEntry key={index} item={item} copyToClipboard={copyToClipboard} />
        ))
    ) : (
      loadingMessage && <div className="loading-animation">{loadingMessage}</div>
    )}
    {filteredItems.length > visibleItemsCount && (
      <button className="showMore" onClick={handleShowMore}>
        Zobrazit více
      </button>
    )}
  </div>
</section>


            {/* TradingView Widget */}
{/* TradingView Widget */}

<TradingViewWidget />




            {/* Přehled a Nav */}
            <section className="overview">
      <h2 className="overh2">Přehled</h2>
      <span className="overspan">{winRate}%</span>
      <p className="description">Toto je přehled celkového winratu.</p>

      <div className="progress-bar">
        <div
          className="progress-bar-inner"
          style={{ width: `${winRate}%` }}
        ></div>
      </div>

      <div className="progress-scale">
        <span>0</span>
        <span>100</span>
      </div>
    </section>

    
    <nav className="navmain">
  <Link to="/"><button className="actived">Přehled</button></Link>
  <Link to="/"><button className="off">Statistiky</button></Link>
  <Link to="/"><button className="off">Signály</button></Link>
  <Link to="/"><button className="off">Beta</button></Link>
  <a href="https://byxbot.com/discord/" target="_blank" rel="noopener noreferrer">
    <button className="support">Podpora</button>
  </a>
</nav>

<Routes>
  <Route path="/" element={<div></div>} />
  <Route path="/statistiky" element={<div>Statistiky</div>} />
  <Route path="/signaly" element={<div>Signály</div>} />
  <Route path="/beta" element={<div>Beta</div>} />
</Routes>

  

        <section className="news">
        <marquee className="crypto-news" behavior="scroll" direction="left" scrollamount="3">
        <div className="news-item">
          <span>🚀 Bitcoin překonává $65K díky ETF přílivu a čínskému stimulu!</span>
          <button className="newsbutton" onClick={() => window.open('https://coin360.com/news/coin360-daily-dispatch-crypto-market-updates-and-highlights-september-26-2024', '_blank')}>
            Zobrazit více
          </button>
        </div>
        <div className="news-item">
          <span>🔗 Thorchain (RUNE) provádí Hard Fork: Zlepšení pro decentralizované výměny.</span>
          <button className="newsbutton" onClick={() => window.open('https://blog.millionero.com/blog/14-key-crypto-events-in-sept-2024-their-market-impact/', '_blank')}>
            Zobrazit více
          </button>
        </div>
        <div className="news-item">
          <span>⚡️ WazirX získává prodloužení dluhu o $230M po červencovém hacku.</span>
          <button className="newsbutton" onClick={() => window.open('https://coin360.com/news/coin360-daily-dispatch-crypto-market-updates-and-highlights-september-26-2024', '_blank')}>
            Zobrazit více
          </button>
        </div>
        <div className="news-item">
          <span>💼 Ethena Labs spouští nový stablecoin UStb ve spolupráci s BlackRock!</span>
          <button className="newsbutton" onClick={() => window.open('https://coin360.com/news/coin360-daily-dispatch-crypto-market-updates-and-highlights-september-26-2024', '_blank')}>
            Zobrazit více
          </button>
        </div>
        <div className="news-item">
          <span>🐹 Hamster Kombat Token airdrop přitahuje kontroverzi</span>
          <button className="newsbutton" onClick={() => window.open('https://coin360.com/news/coin360-daily-dispatch-crypto-market-updates-and-highlights-september-26-2024', '_blank')}>
            Zobrazit více
          </button>
        </div>
        <div className="news-item">
          <span>🌐 Polkastarter V4 je tu: Zlepšení funkcí pro projekty na blockchainu</span>
          <button className="newsbutton" onClick={() => window.open('https://blockchainreporter.net/major-upcoming-crypto-events-in-september-2024/', '_blank')}>
            Zobrazit více
          </button>
        </div>
        <div className="news-item">
          <span>🔒 Numerai spouští V5 Atlas: Revoluce v datově řízených investicích</span>
          <button className="newsbutton" onClick={() => window.open('https://blog.millionero.com/blog/14-key-crypto-events-in-sept-2024-their-market-impact/', '_blank')}>
            Zobrazit více
          </button>
        </div>
        <div className="news-item">
          <span>📈 Bitcoin těží z čínských stimulačních opatření, zatímco Ethereum ETF ztrácí</span>
          <button className="newsbutton" onClick={() => window.open('https://coin360.com/news/coin360-daily-dispatch-crypto-market-updates-and-highlights-september-24-2024', '_blank')}>
            Zobrazit více
          </button>
        </div>

        {/* Duplicitní obsah pro nepřerušovaný posun */}
        <div className="news-item">
          <span>🚀 Bitcoin překonává $65K díky ETF přílivu a čínskému stimulu!</span>
          <button className="newsbutton" onClick={() => window.open('https://coin360.com/news/coin360-daily-dispatch-crypto-market-updates-and-highlights-september-26-2024', '_blank')}>
            Zobrazit více
          </button>
        </div>
        <div className="news-item">
          <span>🔗 Thorchain (RUNE) provádí Hard Fork: Zlepšení pro decentralizované výměny.</span>
          <button className="newsbutton" onClick={() => window.open('https://blog.millionero.com/blog/14-key-crypto-events-in-sept-2024-their-market-impact/', '_blank')}>
            Zobrazit více
          </button>
        </div>
        <div className="news-item">
          <span>⚡️ WazirX získává prodloužení dluhu o $230M po červencovém hacku.</span>
          <button className="newsbutton" onClick={() => window.open('https://coin360.com/news/coin360-daily-dispatch-crypto-market-updates-and-highlights-september-26-2024', '_blank')}>
            Zobrazit více
          </button>
        </div>
        <div className="news-item">
          <span>💼 Ethena Labs spouští nový stablecoin UStb ve spolupráci s BlackRock!</span>
          <button className="newsbutton" onClick={() => window.open('https://coin360.com/news/coin360-daily-dispatch-crypto-market-updates-and-highlights-september-26-2024', '_blank')}>
            Zobrazit více
          </button>
        </div>
        <div className="news-item">
          <span>🐹 Hamster Kombat Token airdrop přitahuje kontroverzi</span>
          <button className="newsbutton" onClick={() => window.open('https://coin360.com/news/coin360-daily-dispatch-crypto-market-updates-and-highlights-september-26-2024', '_blank')}>
            Zobrazit více
          </button>
        </div>
        <div className="news-item">
          <span>🌐 Polkastarter V4 je tu: Zlepšení funkcí pro projekty na blockchainu</span>
          <button className="newsbutton" onClick={() => window.open('https://blockchainreporter.net/major-upcoming-crypto-events-in-september-2024/', '_blank')}>
            Zobrazit více
          </button>
        </div>
        <div className="news-item">
          <span>🔒 Numerai spouští V5 Atlas: Revoluce v datově řízených investicích</span>
          <button className="newsbutton" onClick={() => window.open('https://blog.millionero.com/blog/14-key-crypto-events-in-sept-2024-their-market-impact/', '_blank')}>
            Zobrazit více
          </button>
        </div>
        <div className="news-item">
          <span>📈 Bitcoin těží z čínských stimulačních opatření, zatímco Ethereum ETF ztrácí</span>
          <button className="newsbutton" onClick={() => window.open('https://coin360.com/news/coin360-daily-dispatch-crypto-market-updates-and-highlights-september-24-2024', '_blank')}>
            Zobrazit více
          </button>
        </div>
      </marquee>
    </section>




            {/* Statistiky */}
            <section className="stats-table">
      <div className="stats-header">
        <div className="stat">
          <strong>Winrate:</strong> {winRate}%
        </div>
        <div className="stat">
          <strong>Wins:</strong> {winsCount}
        </div>
        <div className="stat">
          <strong>Losses:</strong> {lossesCount}
        </div>
      </div>
      <div className="time-range">
      <button
  className={timeRange === '24h' ? 'activedd' : ''}
  style={{ cursor: timeRange === '24h' ? 'not-allowed' : 'not-allowed' }}
  onClick={() => setTimeRange('24h')}
  disabled={timeRange === '24h'}
>
  24h
</button>
<button
  className={timeRange === '7d' ? 'activedd' : ''}
  style={{ cursor: timeRange === '7d' ? 'not-allowed' : 'not-allowed' }}
  onClick={() => setTimeRange('7d')}
  disabled={timeRange === '7d'}
>
  7d
</button>
<button
  className={timeRange === '30d' ? 'activedd' : ''}
  style={{ cursor: timeRange === '30d' ? 'not-allowed' : 'not-allowed' }}
  onClick={() => setTimeRange('30d')}
  disabled={timeRange === '30d'}
>
  30d
</button>
        <button 
          className={timeRange === 'all' ? 'active' : 'active'} 
          onClick={() => setTimeRange('all')}
        >
          Celkem
        </button>
      </div>
    </section>



          </section>
        </section>
      </section>
    </div>
  )}
</>


  );
}