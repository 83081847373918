// *PACKAGES* //
import React, { useState, useEffect } from "react";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
// *FUNCTIONS* //
import PostData from './PostData.js'
import VerifyPage from "./VerifyPage.js";
import PasswordResetPage from "./PasswordResetPage.js";
// *STYLES* //
import "./style/Log.css";
import "./style/mobile.css";
import "./style/add.scss";

// *LOGIN PAGE FUNCTION* //
export default function LoginPage({ LoggedIn }) {
  // Email react hooks
  const [Email, SetEmail] = useState("");
  // Password react hooks
  const [Password, SetPassword] = useState("");
  const [ShowPassword, SetShowPassword] = useState(false);
  const [PasswordResetButton, SetPasswordResetButton] = useState(false);
  // Verify form react hooks
  const [VerifyForm, SetVerifyForm] = useState(false);
  // Password reset form react hooks
  const [PasswordResetForm, SetPasswordResetForm] = useState(false);
  // Auto login react hooks
  const [AutoLoginRuned, SetAutoLoginRuned] = useState(false);
  // Notification react hooks
  const [Notification, SetNotification] = useState(null);
  // Loading react hooks
  const [Loading, SetLoading] = useState(false);
  // Loading google react hooks
  const [LoadingGoogle, SetLoadingGoogle] = useState(false);

  // *GET COOKIE FUNCTION* //
  function get_cookie(name) {
    // Get values
    const value = `; ${document.cookie}`;
    // Split values
    const parts = value.split(`; ${name}=`);
    // If lenght is 2, return cookie values
    if (parts.length >= 2) return parts.pop().split(";").shift();
  }

  // *SUBSCRIPTION CHECK FUNCTION* //
  function SubscriptionCheck(check_email = "") {
    // Set loading
    SetLoading(true);

    // Post data [PostData.js]
    PostData("sub_check.php", {email: check_email})
    // Wait for response
    .then((response) => {
        // Convert response into json object
        let response_json = JSON.parse(response);

        // If response not undefined
        if (response_json.message !== undefined || response_json.successful !== undefined) {
            // Set notification
            SetNotification(response_json.message);

            // If response successful is true
            if (response_json.successful) {
                // Call function after 2 seconds
                setTimeout(() => {
                    // Set loading
                    SetLoading(false);
                    // Go back
                    LoggedIn(true);
                }, 300);
            } else {
              // Call function after 2 seconds
              setTimeout(() => {
                  // Set loading
                  SetLoading(false);
                  // Remove email, password and auth_token cookie
                  document.cookie = "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=Lax; Secure`;";
                  document.cookie = "password=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=Lax; Secure`;";
                  document.cookie = "auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=Lax; Secure`;";
                  // Write subscription email into cookie
                  document.cookie = `subscription_email=${check_email}; expires=${new Date(3000, 0, 1)}; path=/; SameSite=Lax; Secure`;
                  // Show subscription activation page
                  window.location.href = process.env.REACT_APP_SUB_PAGE;
              }, 300);
            }
        } else {
            console.error("Subscription check failed:", response); // Print error into console
            SetNotification("Chyba. Prosím, zkus to znovu."); // Set notification
            // Set loading
            SetLoading(false);
        }
    })
    // Catch error
    .catch((error) => {
        console.error("Subscription check failed:", error); // Print error into console
        SetNotification("Chyba. Prosím, zkus to znovu."); // Set notification
        // Set loading
        SetLoading(false);
    });
  }

  // *LOGIN HANDLE FUNCTION* //
  function LoginHandle(data) {
    // Prevent default browser actions
    data.preventDefault();

    // Set loading
    SetLoading(true);

    // Set notification
    SetNotification("");

    // If the fields are not filled in
    if (Email.trim() === "" && Password.trim() === "") {
      SetNotification('Pole musí být vyplněna.'); // Set notification
      // Set loading
      SetLoading(false);
    }
    // If email is not undefined and not includet @ and .
    else if (Email !== undefined && !(Email.includes("@") && Email.includes("."))) {
      SetNotification("Nesprávný formát emailu."); // Set notification
      // Set loading
      SetLoading(false);
    }
    // If the password is not undefined and length is 8-35
    else if (Password !== undefined && !(Password.length >= 8 && Password.length <= 35)) {
      SetNotification("Nesprávné heslo."); // Set notification
      // Set loading
      SetLoading(false);
    }
    // If all is true
    else {
      // Post data [PostData.js]
      PostData("login.php", {email: Email, password: Password})
      // Wait for response
      .then((response) => {
        // Convert response into json object
        let response_json = JSON.parse(response);

        // If response not undefined
        if (response_json.message !== undefined || response_json.successful !== undefined) {
          // Set notification
          SetNotification(response_json.message);

          // If response successful is true
          if (response_json.successful) {
            // Write email and password into cookie
            const cookie_max_age = new Date(3000, 0, 1);
            document.cookie = `email=${Email}; expires=${cookie_max_age}; path=/; SameSite=Lax; Secure`;
            document.cookie = `password=${Password}; expires=${cookie_max_age}; path=/; SameSite=Lax; Secure`;
            document.cookie = `auth_token=${response_json.auth_token}; expires=${cookie_max_age}; path=/; SameSite=Lax; Secure`;

            // Call function after 2 seconds
            setTimeout(() => {
              // Set loading
              SetLoading(false);
              // Go to verify form
              //SetVerifyForm(true);
              // Check subscription
              SubscriptionCheck(Email);
            }, 500);
          } else {
            // Show password reset button
            SetPasswordResetButton(true);
            // Set loading
            SetLoading(false);
          }

        } else {
          console.error("Login failed:", response); // Print error into console
          SetNotification("Přihlášení selhalo. Prosím zkus to znovu."); // Set notification
          // Set loading
          SetLoading(false);
        }
      })
      // Catch error
      .catch((error) => {
        console.error("Login failed:", error); // Print error into console
        SetNotification("Přihlášení selhalo. Prosím zkus to znovu."); // Set notification
        // Set loading
        SetLoading(false);
      });
    }
  }

  // *GOOGLE LOGIN RESPONSE* //
  const GoogleResponse = (google_response) => {
    // Set loading
    SetLoadingGoogle(true);

    // Post data [PostData.js]
    PostData("google_token_verify.php", {token: google_response.credential})
    // Wait for response
    .then((response) => {
      // Convert response into json object
      let response_json = JSON.parse(response);

      // If response not undefined
      if (response_json.message !== undefined || response_json.successful !== undefined || response_json.email !== undefined) {
        // Set notification
        SetNotification(response_json.message);

        // If response successful is true
        if (response_json.successful) {
          // Write email and google_response into cookie
          const cookie_max_age = new Date(3000, 0, 1);
          document.cookie = `email=${response_json.email}; expires=${cookie_max_age}; path=/; SameSite=Lax; Secure`;
          document.cookie = `auth_token=${response_json.auth_token}; expires=${cookie_max_age}; path=/; SameSite=Lax; Secure`;

          // Call function after 2 seconds
          setTimeout(() => {
            // Set loading
            SetLoadingGoogle(false);
            // Subscription check
            SubscriptionCheck(response_json.email);
          }, 500);
        } else {
          // Set loading
          SetLoadingGoogle(false);
        }

      } else {
        console.error("Login failed:", response); // Print error into console
        SetNotification("Přihlášení selhalo. Prosím zkus to znovu."); // Set notification
        // Set loading
        SetLoadingGoogle(false);
      }
    })
    // Catch error
    .catch((error) => {
      console.error("Login failed:", error); // Print error into console
      SetNotification("Přihlášení selhalo. Prosím zkus to znovu."); // Set notification
      // Set loading
      SetLoadingGoogle(false);
    });
  };

  // *GOOGLE ERROR* //
  const handleGoogleError = (google_error_response) => {
    // Set loading
    SetLoadingGoogle(true);

    // Print error into console
    console.error('Login failed:', google_error_response);
    // Set notification
    SetNotification("Přihlášení selhalo");

    // Set loading
    SetLoadingGoogle(false);
  };

  // *AUTO LOGIN* //
  useEffect(() => {
    // if auto login is true, dont run again
    if (AutoLoginRuned) return;
    // Set auto login on true
    SetAutoLoginRuned(true);

    // Set loading
    SetLoading(true);

    // If subscription_email cookie not null
    if (get_cookie('subscription_email')) {
      // Remove cookie
      document.cookie = "subscription_email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    // Get email cookie value
    const cookie_email = get_cookie("email")

    // If email not null
    if (cookie_email && (cookie_email.includes("@") && cookie_email.includes("."))) {
      // Get password and auth_token cookies values
      const cookie_password = get_cookie("password")
      const cookie_auth_token = get_cookie("auth_token")

      // *AUTH TOKEN AUTO LOGIN* //
      if (cookie_auth_token) {
        // Post data [PostData.js]
        PostData("auth_token_verify.php", {email: cookie_email, auth_token: cookie_auth_token})
        // Wait for response
        .then((response) => {
          // Convert response into json object
          let response_json = JSON.parse(response);

          // If response not undefined
          if (response_json.message !== undefined || response_json.successful !== undefined) {
            // Set notification
            SetNotification(response_json.message);

            // If response successful is true
            if (response_json.successful) {
              // Call function after 2 seconds
              setTimeout(() => {
                // Set loading
                SetLoading(false);
                // Subscription chech
                SubscriptionCheck(cookie_email);
              }, 500);
            } else {
              // Set loading
              SetLoading(false);
            }
          } else {
            console.error("Login failed:", response); // Print error into console
            SetNotification("Přihlášení selhalo. Prosím zkus to znovu."); // Set notification
            // Remove cookies
            document.cookie = "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=Lax; Secure`;";
            document.cookie = "password=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            // Set loading
            SetLoading(false);
          }
        })
        // Catch error
        .catch((error) => {
          console.error("Login failed:", error); // Print error into console
          SetNotification("Přihlášení selhalo. Prosím zkus to znovu."); // Set notification
          // Set loading
          SetLoading(false);
        });
      }
      // *PASSWORD AUTO LOGIN* //
      else if (cookie_password && (cookie_password.length >= 8 && cookie_password.length <= 35)) {
        // Post data [PostData.js]
        PostData("login.php", {email: cookie_email, password: cookie_password})
        // Wait for response
        .then((response) => {
          // Convert response into json object
          let response_json = JSON.parse(response);

          // If response not undefined
          if (response_json.message !== undefined || response_json.successful !== undefined) {
            // Set notification
            SetNotification(response_json.message);

            // If response successful is true
            if (response_json.successful) {
              // Call function after 2 seconds
              setTimeout(() => {
                // Set loading
                SetLoading(false);
                // Go to verify form
                //SetVerifyForm(true);
                // Check subscription
                SubscriptionCheck(cookie_email);
              }, 2000);
            } else {
              // Set loading
              SetLoading(false);
            }
          } else {
            console.error("Login failed:", response); // Print error into console
            SetNotification("Přihlášení selhalo. Prosím zkus to znovu."); // Set notification
            // Remove cookies
            document.cookie = "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=Lax; Secure`;";
            document.cookie = "password=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            // Set loading
            SetLoading(false);
          }
        })
        // Catch error
        .catch((error) => {
          console.error("Login failed:", error); // Print error into console
          SetNotification("Přihlášení selhalo. Prosím zkus to znovu."); // Set notification
          // Set loading
          SetLoading(false);
        });
      }
      // *IF ALL IS FALSE* //
      else {
        // Remove cookie
        document.cookie = "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=Lax; Secure`;";
        // Set loading
        SetLoading(false);
      }
    } else {
      // Remove cookie
      document.cookie = "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=Lax; Secure`;";
      // Set loading
      SetLoading(false);
    }
  }, [AutoLoginRuned, SubscriptionCheck]);

  // *RETURN JSX OBJECT* //
  return (
    <>
      {VerifyForm ? (
        <VerifyPage VerifyScript="login_verify.php" VerifySuccess={() => setTimeout(() => {SubscriptionCheck(get_cookie("email"));SetVerifyForm(false);}, 2000)} VerifyBack={SetVerifyForm} />

      ) : (
        <>
        <div className="login-background"></div>
          {PasswordResetForm ? (
            <PasswordResetPage PasswordResetBack={SetPasswordResetForm} />

          ) : (
            <form className="formlog"> {/* Form for login */}
              {/* Form heading */}
              <h1 className="h1log">Přihlášení</h1>

              {/* Email input */}
              <input
                className="inputlog"
                type="text"
                name="Email ID"
                value={Email}
                maxLength="50"
                placeholder="Email"
                onChange={(data) => SetEmail(data.target.value)}
              />

              {/* Password container */}
              <div style={{ position: 'relative' }}>
                {/* Password input */}
                <input
                  className="inputlog"
                  type={ShowPassword ? "text" : "password"}
                  name="Pass"
                  placeholder="Heslo"
                  maxLength="36"
                  value={Password}
                  onChange={(data) => SetPassword(data.target.value)}
                />
                {/* Show/Hide password button */}
                <button
                  className="buttonshow"
                  type="button"
                  style={{ position: 'absolute', right: '0px', top: '-10px', minWidth: "80px", maxWidth: "80px", borderRadius: "0px 50px 50px 0px", backgroundColor: "transparent", border: "none", cursor: "pointer" }}
                  onClick={() => SetShowPassword(!ShowPassword)}
                >{ShowPassword ? "Skrýt" : "Zobrazit"}</button>
                {/* Password reset button */}
                {PasswordResetButton &&
                  <button
                    className="buttonreset"
                    type="button"
                    style={{ position: 'absolute', right: '50px', top: '-10px', minWidth: "80px", maxWidth: "117px", borderRadius: "0px 50px 50px 0px", backgroundColor: "transparent", border: "none", cursor: "pointer" }}
                    onClick={() => SetPasswordResetForm(true)}
                  >{"Obnovit heslo"}</button>
                }
              </div>

              {/* Login button */}
              <button
                onClick={LoginHandle}
                disabled={Loading}
                className="buttonlog_loading"
              >{Loading ? <div className="spinner"></div> : 'Přihlásit se'}</button>

              {/* Google OAuth login button */}
              <div className="google-login">
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <GoogleLogin
      onSuccess={GoogleResponse}
      onError={handleGoogleError}
      text="signin_with"
      width="250"
      logo_alignment="left"
      size="large"
      shape="pill"
      theme="filled_black"
    />
  </GoogleOAuthProvider>
</div>


              {/* Notification */}
              {Notification && <p className="plog">{Notification}</p>}
            </form>
          )}
        </>
      )}
    </>
  );
}