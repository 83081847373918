// *PACKAGES* //
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
// *FUNCTIONS* //
import LoginPage from "./login/LoginPage.js";
import CheckBotStatus from "../src/coin/components/CheckBotStatus.js";
import RegisterPage from "./login/RegisterPage.js";
import HomePage from "./coin/App.js";
import StatusPage from "./status/StatusPage.js"; // Import komponenty pro Status stránku
// *ASSETS* //
import popup_logo from "./assets/logo_transparent.png";
import popup_gif_iphone from "./assets/gif.gif";
import popup_gif_android from "./assets/gif2.gif";
import mobile_placeholder_image from "./assets/mobile_placeholder.png";
// *STYLES* //
import "./login/style/AppSearch.css";
import "./login/style/MobileView.css";

// *DISABLE REACT DEV TOOLS* //
if (process.env.RUN_TYPE === 'production') {
  disableReactDevTools();
}

// *HOME SCREEN POPUP FUNCTION* //
const HomeScreenPopup = ({ onClose }) => {
  const [IsHomeScreen, SetIsHomeScreen] = useState(false);

  useEffect(() => {
    SetIsHomeScreen(window.matchMedia("(display-mode: standalone)").matches || window.navigator.standalone);
  }, []);

  function AddAppToHomeScreen() {
    const popup = document.createElement('div');
    popup.className = 'popupapp2 fadeout';

    const paragraph = document.createElement('p');
    paragraph.textContent = 'Pro plynulé používání si ulož prosím aplikaci ByX na domovskou obrazovku.';

    const gif = document.createElement('img');
    if (navigator.userAgent.includes('iPhone')) {
      gif.src = popup_gif_iphone;
    } else {
      gif.src = popup_gif_android;
    }
    gif.alt = 'gif';
    gif.className = 'gif';

    const close_button = document.createElement('button');
    close_button.textContent = 'Hotovo';
    close_button.className = 'close-button';
    close_button.addEventListener('click', () => {
      popup.classList.add('fadeout-hidden');
      overlay.classList.add('fadeout-hidden');
      setTimeout(() => {
        document.body.removeChild(popup);
        document.body.removeChild(overlay);
        onClose();
      }, 500);
    });

    const overlay = document.createElement('div');
    overlay.className = 'popupapp2-overlay fadeout';

    popup.appendChild(paragraph);
    popup.appendChild(gif);
    popup.appendChild(close_button);
    document.body.appendChild(popup);
    document.body.appendChild(overlay);
  }

  return (
    <>
      {!IsHomeScreen && (
        <div className="popupapp fadeout">
          <img src={popup_logo} alt="logo" className="logoapp" />
          <div>
            <p className="loginappbig">ByX Trading</p>
            <p className="loginappsmall">Ulož si aplikaci pro plynulejší použití.</p>
          </div>
          <button className="buttonapp" onClick={AddAppToHomeScreen}>Uložit</button>
        </div>
      )}
    </>
  );
};

// *APP FUNCTION* //
export default function App({ showRegister }) {
  const [loggedIn, setLoggedIn] = useState(false);
  const [isRegisterForm, setIsRegisterForm] = useState(showRegister);
  const [showHomeScreenPopup, setShowHomeScreenPopup] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation(); // Get current route

  // Detect mobile devices
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android|iphone|ipad|ipod/i.test(userAgent)) {
      setIsMobile(true);
    }
  }, []);

  // Conditionally show the popup only if the current route is not /status
  const shouldShowHomeScreenPopup = showHomeScreenPopup && location.pathname === "/";

  // If mobile and on the root route "/", restrict the view
  if (isMobile && location.pathname === "/") {
    return (
      <div className="App mobile-view">
        <CheckBotStatus />
        <img src={mobile_placeholder_image} alt="Mobile Placeholder" className="mobile-placeholder" />
      </div>
    );
  }

  // Render normal app for non-mobile devices and allow /status route on mobile
  return (
    
    <div className="App">
      {shouldShowHomeScreenPopup && <HomeScreenPopup onClose={() => setShowHomeScreenPopup(false)} />}

      <Routes>
        {/* Hlavní route pro login/registraci a domovskou stránku */}
        <Route path="/" element={!loggedIn ? (
            <>
              {isRegisterForm ? (
                <>
                  <RegisterPage RegisterSuccess={setIsRegisterForm} />
                  <button className="buttonlog2" onClick={() => setIsRegisterForm(false)}>Již máš účet?</button>
                </>
              ) : (
                <>
                  <LoginPage LoggedIn={setLoggedIn} />
                  <button className="buttonlog2" onClick={() => setIsRegisterForm(true)}>Nemáš účet? Založ si ho zde.</button>
                </>
              )}
            </>
          ) : (
            <HomePage />
          )} />

        {/* Route pro stránku Status */}
        <Route path="/status" element={<StatusPage />} />
      </Routes>
    </div>
  );
}
